export const defaultSorted = [{dataField: "id", order: "asc"}];
export const sizePerPageList = [
    {text: "3", value: 3},
    {text: "5", value: 5},
    {text: "10", value: 10},
];
export const initialFilter = {
    sortOrder: "asc", // asc||desc
    sortField: "id",
    pageNumber: 1,
    pageSize: 10,
};


export const emailNotValid = [
    "rpg_admin@tusker.ai",
    "rpg.admin.supervisor@tusker.ai",
    "rpg.glovesproduction.supervisor@tusker.ai",
    "rpg.gmdcaproduction.supervisor@tusker.ai",
    "rpg.store.supervisor@tusker.ai",
    "rpg.maintence.supervisor@tusker.ai",
    "rpg.toolroom.supervisor@tusker.ai",
]