import React, {useEffect, useState} from "react";
import {
    Col,
    Row,
} from "react-bootstrap";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import {CardBody} from "reactstrap";
import {warningToast} from "../../../../../../utils/ToastMessage";
import {MyAllCameraStatusTable} from "./MyAllCameraStatusTable";
import {getAllCameraStatus} from "../../_redux/MyAllCameraApi";

function MyAllCameraStatusCard() {
    const [currentItems, setCurrentItems] = useState([]);
    const [showTable, setShowTable] = useState(true);
    const [pageNo, setPageNo] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalCount, setTotalCount] = useState(0);

    useEffect(() => {
        const cameraId = {
            page_number: pageNo,
            page_size: pageSize,
        }
        getCameraStatus(cameraId);

    }, [pageNo, pageSize]);



    function getCameraStatus(body) {
        setShowTable(true);
        getAllCameraStatus(body)
            .then(response => {
                if (response && response.isSuccess) {
                    if (response.data.items.length > 0) {
                        const pageInfo = response.data.page_info;

                        // Handle next/previous page logic
                        if (pageInfo.next_page) {
                          setPageNo(pageInfo.next_page - 1); // Set to current page
                        } else if (pageInfo.pre_page) {
                          setPageNo(pageInfo.pre_page + 1); // If no next page, fallback to previous page
                        } else {
                          setPageNo(1); // Default to page 1 if no page info available
                        }

                        // Update pagination state
                        setPageSize(pageInfo.page_size);
                        setTotalCount(pageInfo.total_count);
                        setShowTable(false);
                        setCurrentItems(response.data.items);
                    }
                } else throw new Error();
            })
            .catch(error => {
                setShowTable(false);
                setCurrentItems([]);
                if (error.detail) {
                    warningToast(error.detail);
                } else {
                    warningToast("Something went Wrong");
                }
            });
    }

    const sizePerPageChangeApiCall = (page, sizePerPage) => {
        setPageNo(1);
        setPageSize(sizePerPage);

    };

    const pageChange = (page, sizePerPage) => {
        setPageNo(page);
        setPageSize(sizePerPage);
    };

    return (
        <Card className="example example-compact ">
            <Row>
                <Col xl={8} xs={12} md={7} sm={12} lg={8}>
                    <CardHeader title="Camera Status"/>
                </Col>
            </Row>
            <hr/>
            <CardBody style={{padding: "1rem "}}>
                <MyAllCameraStatusTable
                    getAllCameraLocationModal={getCameraStatus}
                    currentItems={currentItems}
                    setCurrentItems={setCurrentItems}
                    showTable={showTable}
                    pageNo={pageNo}
                    pageSize={pageSize}
                    totalCount={totalCount}
                    setPageSize={setPageSize}
                    setPageNo={setPageNo}
                    pageChange={(page, sizePerPage) => pageChange(page, sizePerPage)}
                    sizePerPageChangeApiCall={(page, sizePerPage) =>
                        sizePerPageChangeApiCall(page, sizePerPage)
                    }
                />
            </CardBody>
        </Card>
    );
}

export default MyAllCameraStatusCard;
