import React from "react";
import MyAllCameraStatusCard from "./Components/MyAllCameraTable/MyAllCameraCard";

function MyAllCameraStatus() {
  return (
    <div>
        <MyAllCameraStatusCard />
    </div>
  );
}

export default MyAllCameraStatus;
