import React from "react";
import MyAllCameraLogCard from "./Components/MyAllCameraTable/MyAllCameraCard";

function MyAllCameraLog() {
  return (
    <div>
        <MyAllCameraLogCard />
    </div>
  );
}

export default MyAllCameraLog;
